'use client'

import { PropsWithChildren, useCallback, useMemo, useState } from 'react'

import { UiState } from '@marketplace-web/shared/ui-helpers'
import {
  deleteFeedback,
  deleteFeedbackComment,
  editFeedbackComment,
  getFeedback,
  getUserFeedback,
  moderateFeedback,
} from 'data/api'
import { transformFeedback, transformFeedbackResponse } from 'data/transformers/feedback'

import { FeedbackFilterType } from '../../../constants'

import FeedbackContext, {
  FeedbackContextType,
  FeedbackItem,
  FeedbackScreen,
} from './FeedbackContext'

const FeedbackProvider = ({ children }: PropsWithChildren<unknown>) => {
  const [feedbackItemStateById, setFeedbackItemStateById] = useState<
    FeedbackContextType['feedbackItemStateById']
  >({})
  const [pagination, setPagination] = useState<FeedbackContextType['pagination']>({
    currentPage: 0,
    totalPages: 0,
  })
  const [uiState, setUiState] = useState<FeedbackContextType['uiState']>(UiState.Idle)
  const [showFilters, setShowFilters] = useState<FeedbackContextType['showFilters']>(undefined)
  const [ids, setIds] = useState<FeedbackContextType['ids']>([])
  const [byId, setById] = useState<FeedbackContextType['byId']>({})
  const [byTranslatedId, setByTranslatedId] = useState<FeedbackContextType['byTranslatedId']>({})

  const getFeedbackById = useCallback(
    (id: number) => {
      const isTranslated = feedbackItemStateById[id]?.isTranslated

      if (isTranslated) return byTranslatedId[id]

      return byId[id]
    },
    [byId, byTranslatedId, feedbackItemStateById],
  )

  const getEndReached = useCallback(
    () => !!pagination.totalPages && pagination.currentPage >= pagination.totalPages,
    [pagination.currentPage, pagination.totalPages],
  )

  const resetCurrentPage = useCallback(
    () => setPagination(previousValue => ({ ...previousValue, currentPage: 0 })),
    [],
  )

  const getFeedbackItemStateById = useCallback(
    (id: number) => feedbackItemStateById[id],
    [feedbackItemStateById],
  )

  const setScreenById = useCallback(
    (id: number, screen: FeedbackScreen) => {
      const currentState = feedbackItemStateById[id]

      if (!currentState) return

      setFeedbackItemStateById(previousValue => ({
        ...previousValue,
        [id]: { ...currentState, screen },
      }))
    },
    [feedbackItemStateById],
  )

  const fetchFeedback = useCallback(
    async (userId: number, feedbackFilterType?: FeedbackFilterType) => {
      setUiState(UiState.Pending)

      const response = await getUserFeedback({
        userId,
        perPage: 20,
        page: pagination.currentPage + 1,
        feedbackFilterType,
      })

      if ('errors' in response) {
        setUiState(UiState.Failure)

        return
      }

      setUiState(UiState.Success)

      const transformedData = transformFeedbackResponse(response)

      setPagination({
        currentPage: transformedData.pagination.currentPage,
        totalPages: transformedData.pagination.totalPages,
      })

      if (showFilters === undefined) {
        setShowFilters(transformedData.feedback.length > 0)
      }

      transformedData.feedback.forEach(item => {
        setFeedbackItemStateById(previousValue => ({
          ...previousValue,
          [item.id]: {
            uiState: UiState.Idle,
            screen: FeedbackScreen.View,
            error: null,
            isTranslated: false,
          },
        }))

        setById(previousValue => ({ ...previousValue, [item.id]: item }))
        setIds(previousValue => [...previousValue, item.id])
      })
    },
    [pagination.currentPage, showFilters],
  )

  const updateFeedbackItemStateById = useCallback(
    (id: number, newState: Partial<FeedbackItem>) => {
      const currentState = feedbackItemStateById[id]

      if (!currentState) return

      setFeedbackItemStateById(previousValue => ({
        ...previousValue,
        [id]: { ...currentState, ...newState },
      }))
    },
    [feedbackItemStateById],
  )

  const deleteFeedbackById = useCallback(
    async (id: number) => {
      updateFeedbackItemStateById(id, { uiState: UiState.Pending })

      const response = await deleteFeedback({ feedbackId: id })

      if ('errors' in response) {
        updateFeedbackItemStateById(id, { uiState: UiState.Failure, error: response })

        return
      }

      updateFeedbackItemStateById(id, { uiState: UiState.Success, error: null })
      setIds(previousValue => previousValue.filter(feedbackId => feedbackId !== id))
      delete byId[id]
    },
    [byId, updateFeedbackItemStateById],
  )

  const moderateFeedbackById = useCallback(
    async (id: number, userId: number, otherUserId: number) => {
      updateFeedbackItemStateById(id, { uiState: UiState.Pending })

      await moderateFeedback({ feedbackId: id, userId, otherUserId })

      updateFeedbackItemStateById(id, { uiState: UiState.Success, error: null })
      setIds(previousValue => previousValue.filter(feedbackId => feedbackId !== id))
      delete byId[id]
    },
    [byId, updateFeedbackItemStateById],
  )

  const deleteCommentByFeedbackId = useCallback(
    async (id: number) => {
      updateFeedbackItemStateById(id, { uiState: UiState.Pending })

      const response = await deleteFeedbackComment({ feedbackId: id })

      if ('errors' in response) {
        updateFeedbackItemStateById(id, { uiState: UiState.Pending, error: response })

        return
      }

      const transformedData = transformFeedback(response.feedback)

      updateFeedbackItemStateById(id, {
        uiState: UiState.Success,
        error: null,
        isTranslated: false,
        screen: FeedbackScreen.View,
      })

      setById(previousValue => ({ ...previousValue, [transformedData.id]: transformedData }))
      delete byTranslatedId[transformedData.id]
    },
    [byTranslatedId, updateFeedbackItemStateById],
  )

  const initiateTranslation = useCallback(
    async (id: number, localize: boolean) => {
      if (!localize) {
        updateFeedbackItemStateById(id, {
          isTranslated: localize,
        })

        return
      }

      const translatedFeedback = byTranslatedId[id]

      if (translatedFeedback) {
        updateFeedbackItemStateById(id, {
          isTranslated: localize,
        })

        return
      }

      updateFeedbackItemStateById(id, {
        uiState: UiState.Pending,
      })

      const response = await getFeedback({ feedbackId: id, localize })

      if ('errors' in response) {
        updateFeedbackItemStateById(id, {
          uiState: UiState.Failure,
          error: response,
        })

        return
      }

      const transformedData = transformFeedback(response.user_feedback)

      setByTranslatedId(previousValue => ({
        ...previousValue,
        [transformedData.id]: transformedData,
      }))
      setById(previousValue => ({ ...previousValue, [transformedData.id]: transformedData }))
      updateFeedbackItemStateById(id, {
        uiState: UiState.Success,
        error: null,
        isTranslated: localize,
      })
    },
    [byTranslatedId, updateFeedbackItemStateById],
  )

  const editComment = useCallback(
    async (id: number, comment: string) => {
      updateFeedbackItemStateById(id, { uiState: UiState.Pending })

      const response = await editFeedbackComment({ feedbackId: id, comment })

      if ('errors' in response) {
        updateFeedbackItemStateById(id, {
          uiState: UiState.Failure,
          error: response,
        })

        return
      }

      const transformedData = transformFeedback(response.feedback)

      updateFeedbackItemStateById(id, {
        uiState: UiState.Success,
        error: null,
        screen: FeedbackScreen.View,
      })
      setById(previousValue => ({ ...previousValue, [transformedData.id]: transformedData }))
      delete byTranslatedId[transformedData.id]
    },
    [byTranslatedId, updateFeedbackItemStateById],
  )

  const value = useMemo(
    () => ({
      feedbackItemStateById,
      pagination,
      uiState,
      showFilters,
      ids,
      byId,
      byTranslatedId,
      editComment,
      updateFeedbackItemStateById,
      initiateTranslation,
      deleteCommentByFeedbackId,
      moderateFeedbackById,
      deleteFeedbackById,
      getFeedbackItemStateById,
      setScreenById,
      setByTranslatedId,
      setById,
      setIds,
      setShowFilters,
      setUiState,
      setPagination,
      setFeedbackItemStateById,
      fetchFeedback,
      getEndReached,
      resetCurrentPage,
      getFeedbackById,
    }),
    [
      feedbackItemStateById,
      pagination,
      uiState,
      showFilters,
      ids,
      byId,
      byTranslatedId,
      editComment,
      updateFeedbackItemStateById,
      initiateTranslation,
      deleteCommentByFeedbackId,
      deleteFeedbackById,
      moderateFeedbackById,
      getFeedbackItemStateById,
      setScreenById,
      fetchFeedback,
      getEndReached,
      resetCurrentPage,
      getFeedbackById,
    ],
  )

  return <FeedbackContext.Provider value={value}>{children}</FeedbackContext.Provider>
}

export default FeedbackProvider
