'use client'

import { Button, Icon } from '@vinted/web-ui'
import { ArrowBack12, Pencil12, X12, Flag12 } from '@vinted/monochrome-icons'

import { FormattedMessage } from '@marketplace-web/shared/i18n'
import { SeparatedList } from '@marketplace-web/shared/ui-helpers'

import { FeedbackScreen, useFeedbackContext } from '../../FeedbackProvider'

type Props = {
  id: number
  editFeedbackUrl: string
  canReply: boolean
  canChange: boolean
  canDelete: boolean
  canModerate: boolean
}

const FeedbackActions = ({
  id,
  editFeedbackUrl,
  canChange,
  canDelete,
  canReply,
  canModerate,
}: Props) => {
  const { setScreenById } = useFeedbackContext()

  const handleReply = () => {
    setScreenById(id, FeedbackScreen.CreateComment)
  }

  const handleDelete = () => {
    setScreenById(id, FeedbackScreen.DeleteFeedback)
  }

  const handleModerate = () => {
    setScreenById(id, FeedbackScreen.ModerateFeedback)
  }

  return (
    <SeparatedList>
      {canReply && (
        <Button
          text={<FormattedMessage id="user.feedback.reply" />}
          icon={<Icon name={ArrowBack12} color="parent" />}
          inline
          size={Button.Size.Small}
          theme="muted"
          onClick={handleReply}
          testId="feedback-reply-action"
        />
      )}
      {canChange && (
        <Button
          text={<FormattedMessage id="user.feedback.edit" />}
          icon={<Icon name={Pencil12} color="parent" />}
          inline
          size={Button.Size.Small}
          theme="muted"
          url={editFeedbackUrl}
          testId="feedback-edit-action"
        />
      )}
      {canDelete && (
        <Button
          text={<FormattedMessage id="user.feedback.delete" />}
          icon={<Icon name={X12} color="parent" />}
          inline
          size={Button.Size.Small}
          theme="muted"
          onClick={handleDelete}
          testId="feedback-delete-action"
        />
      )}
      {canModerate && (
        <Button
          text={<FormattedMessage id="user.feedback.moderate" />}
          icon={<Icon name={Flag12} color="parent" />}
          inline
          size={Button.Size.Small}
          theme="muted"
          onClick={handleModerate}
          testId="feedback-moderate-action"
        />
      )}
    </SeparatedList>
  )
}

export default FeedbackActions
