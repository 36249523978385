'use client'

import {
  useState,
  useEffect,
  ReactNode,
  MouseEvent,
  useCallback,
  ReactComponentElement,
} from 'react'
import { Button, Card, Cell, Divider, Spacer } from '@vinted/web-ui'

import { SeparatedList } from '@marketplace-web/shared/ui-helpers'

export type DropdownActionsType = {
  id: string
  text: ReactNode
  url?: string
  prefix?: ReactNode
  onClick?: () => void
}

type Props = {
  actions: Array<DropdownActionsType>
  buttonProps: ComponentProps<typeof Button>
  separator?: ReactComponentElement<typeof Divider | typeof Spacer>
  styling?: (typeof Cell.Styling)[keyof typeof Cell.Styling]
}

const DropdownMenu = ({ actions, buttonProps, styling, separator }: Props) => {
  const [isDropdownVisible, setIsDropdownVisible] = useState(false)

  const handleDocumentClick = useCallback(() => {
    setIsDropdownVisible(false)
  }, [])

  useEffect(() => {
    if (isDropdownVisible) {
      document.addEventListener('click', handleDocumentClick)
    }

    return () => {
      document.removeEventListener('click', handleDocumentClick)
    }
  }, [isDropdownVisible, handleDocumentClick])

  const handleButtonClick = (event: MouseEvent) => {
    event.stopPropagation()

    setIsDropdownVisible(prevIsDropdownVisible => !prevIsDropdownVisible)
  }

  const renderActions = () =>
    actions.map(action => {
      const { id, text, url, prefix, onClick } = action

      return (
        <Cell
          prefix={prefix}
          testId={`${id}-button`}
          key={id}
          body={text}
          styling={styling || Cell.Styling.Narrow}
          type={Cell.Type.Navigating}
          url={url}
          onClick={onClick}
        />
      )
    })

  const renderSeparatedList = () => (
    <SeparatedList separator={separator}>{renderActions()}</SeparatedList>
  )

  return (
    <>
      <Button {...buttonProps} onClick={handleButtonClick} />
      {isDropdownVisible && (
        <div className="dropdown-menu">
          <Card styling={Card.Styling.Lifted}>
            {separator ? renderSeparatedList() : renderActions()}
          </Card>
        </div>
      )}
    </>
  )
}

export default DropdownMenu
