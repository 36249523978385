'use client'

import { Chip, Text, Spacer, Cell } from '@vinted/web-ui'

import { SeparatedList } from '@marketplace-web/shared/ui-helpers'
import { useTranslate } from '@marketplace-web/shared/i18n'
import { useTracking } from '@marketplace-web/shared/event-tracker'
import { clickEvent } from '_libs/common/event-tracker/events'
import { ClickableElement } from 'constants/tracking/clickable-elements'
import { Screen } from 'constants/tracking/screens'

import { FeedbackFilterType } from '../../../constants'

type Props = {
  selectedFeedbackFilterType: FeedbackFilterType
  showFilters?: boolean
  onClick: (feedbackFilterType: FeedbackFilterType) => void
}

const FeedbackFilters = ({ selectedFeedbackFilterType, showFilters, onClick }: Props) => {
  const translate = useTranslate('user.feedback.filters')
  const { track } = useTracking()

  const getCurrentTargetElement = (feedbackFilterType: FeedbackFilterType) => {
    switch (feedbackFilterType) {
      case FeedbackFilterType.Auto:
        return ClickableElement.FeedbackAutoReviewsFilter
      case FeedbackFilterType.Members:
        return ClickableElement.FeedbackMemberReviewsFilter
      default:
        return ClickableElement.FeedbackAllReviewsFilter
    }
  }

  const handleClick = (feedbackFilterType: FeedbackFilterType) => () => {
    track(
      clickEvent({
        target: getCurrentTargetElement(feedbackFilterType),
        screen: Screen.UserProfile,
      }),
    )
    onClick(feedbackFilterType)
  }

  if (!showFilters) return null

  return (
    <Cell>
      <div className="u-flexbox">
        <SeparatedList separator={<Spacer orientation={Spacer.Orientation.Vertical} />}>
          <Chip
            activated={selectedFeedbackFilterType === FeedbackFilterType.All}
            text={translate('all')}
            textType={Text.Type.Subtitle}
            onClick={handleClick(FeedbackFilterType.All)}
            radius={Chip.Radius.Round}
          />
          <Chip
            activated={selectedFeedbackFilterType === FeedbackFilterType.Members}
            text={translate('members')}
            textType={Text.Type.Subtitle}
            onClick={handleClick(FeedbackFilterType.Members)}
            radius={Chip.Radius.Round}
          />
          <Chip
            activated={selectedFeedbackFilterType === FeedbackFilterType.Auto}
            text={translate('auto')}
            textType={Text.Type.Subtitle}
            onClick={handleClick(FeedbackFilterType.Auto)}
            radius={Chip.Radius.Round}
          />
        </SeparatedList>
      </div>
    </Cell>
  )
}

export default FeedbackFilters
