'use client'

import { Dialog } from '@vinted/web-ui'

import { useTranslate } from '@marketplace-web/shared/i18n'

type Props = {
  isOpen: boolean
  onSubmit: () => void
  onClose: () => void
}

const DeleteFeedbackModal = ({ isOpen, onClose, onSubmit }: Props) => {
  const translate = useTranslate('user.feedback.modal')

  return (
    <Dialog
      show={isOpen}
      title={translate('title')}
      body={translate('body')}
      actions={[
        {
          text: translate('action_delete'),
          style: Dialog.ActionStyling.Filled,
          theme: 'warning',
          testId: 'feedback-delete-action-confirm',
          callback: onSubmit,
        },
        {
          text: translate('action_close'),
          testId: 'feedback-delete-action-cancel',
          callback: onClose,
        },
      ]}
    />
  )
}

export default DeleteFeedbackModal
